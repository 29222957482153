import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import PageHeading from "../../components/page_heading";
import { BlogThumbnail } from "../../components/pages/blog";
import { Centerer } from "../../components/utils";

const BlogPage = ({ data }) => {
  const nodes = data.allFile.nodes;
  const nodeElems = nodes.map((node, index) => {
    const frontmatter = node.childMdx.frontmatter;
    const tags = frontmatter.tags ? frontmatter.tags.split(" ") : undefined;
    const url = `/blog/${node.childMdx.slug}`;
    let gatsbyImageData = undefined;
    if (frontmatter.myimage) {
      gatsbyImageData = frontmatter.myimage.childImageSharp.gatsbyImageData;
    }

    data = {
      title: frontmatter.title,
      gatsbyImageData: gatsbyImageData,
      description: frontmatter.description,
      date: frontmatter.date,
      tags: tags,
      url: url,
    };

    return <BlogThumbnail data={data} key={index} />;
  });
  return (
    <Layout heroSrc={undefined} iconName="blog" pageWidth="80em">
      <PageHeading
        headingText="Blog Posts"
        subHeadingText="Posts I write to remind myself and others of some useful tipps"
      />
      <Centerer>{nodeElems}</Centerer>
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        name: {}
        extension: { eq: "mdx" }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            myimage {
              childImageSharp {
                gatsbyImageData
              }
            }
            tags
            description
          }
          id
          slug
        }
      }
    }
  }
`;

export default BlogPage;
