import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";
import styled, { css } from "styled-components";
import Tag2Color from "../../content/tags.json";

const tagstyle = css`
  margin: 0.2em;
  border-radius: 10%;
  border: 0px;
  font-size: 15px;
  background-color: ${(props) =>
    props.textTag && Tag2Color.hasOwnProperty(props.textTag)
      ? Tag2Color[props.textTag]
      : "#bbbbbb"};
  padding: 7px 7px 7px 7px;
  color: rgb(255, 255, 255);
  filter: brightness(100%);
  border-radius: 0.25rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  --bg-opacity: 0.25;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 300ms;
`;

const Tag = styled.p`
  ${tagstyle}
  display: inline-block;
`;

const HeaderBox = styled.div`
  margin-right: 1.2em;
`;
const ThumbnailBgImage = styled(BackgroundImage)`
  display: flex;
  overflow: "hidden";
  flex-direction: column;
  justify-content: center;
  width: "100%";
  overflow: "hidden";
  margin-bottom: "0.2em";
`;

const ThumbnailHeaderTitle = styled.h2`
  text-align: center;
  &::after {
    border-radius: 1em; !important
  }
`;

const ThumbnailHeaderDate = styled.p`
  background-color: #eeeeee;
  border-radius: 6%;
  color: #333333;
`;

const NoBgPictureContainer = styled.div`
border-radius:0.3em`;

const ThumbnailHeader = ({ title, tags, date, gatsbyImageData }) => {
  /* The resource header consists of an optional bg img, title text and tags*/
  const tagsitems = tags
    ? tags.map((textTag, index) => (
        <Tag key={`${title}_${index}`} textTag={textTag}>
          #{textTag}
        </Tag>
      ))
    : undefined;
  function formatDate(date) {
    return `${date}`;
  }
  const dateElem = date ? (
    <ThumbnailHeaderDate>{formatDate(date)}</ThumbnailHeaderDate>
  ) : (
    <></>
  );
  const innerElements = (
    <ThumbnailHeaderTitle className="resource-title">
      {title}
    </ThumbnailHeaderTitle>
  );
  const hasImage = gatsbyImageData !== undefined;
  let header = undefined;
  // TODO: refactor the if block
  if (hasImage) {
    const image = getImage(gatsbyImageData);
    const bgImage = convertToBgImage(image);

    header = (
      <ThumbnailBgImage
        style={{ borderRadius: "0.3em", overflow: "hidden" }}
        Tag="section"
        {...bgImage}
      >
        {innerElements}
      </ThumbnailBgImage>
    );
  } else {
    header = (
      <NoBgPictureContainer className="resource-bg">
        {innerElements}
      </NoBgPictureContainer>
    );
  }
  return (
    <HeaderBox>
      {header}
      {dateElem}
      {tagsitems}
    </HeaderBox>
  );
};

const ThumbnailBody = styled.p`
  flex: 0 1 auto;
  overflow: auto;
`;

// TODO: try to style ThumbnailBox directly, accessing the div via classname/type
const StyledBox = styled.div`
  display: flex;
  width: 300px;
  max-height: 400px;
  flex-direction: column;
`;

const WrapperLink = styled.a`
  text-decoration: none;
  color: black;
  transition: transform 0.3s ease-out;
  display: inline-block;

  :hover {
    transform: translate(0, -10px);
    color: ${({ theme }) => theme.maincolor.color1};
  }
`;

const Thumbnail = ({ className, data }) => {
  return (
    <WrapperLink href={data.url} className={className}>
      <StyledBox>
        <ThumbnailHeader
          title={data.title}
          tags={data.tags}
          gatsbyImageData={data.gatsbyImageData}
          date={data.date}
        />
        <ThumbnailBody className="resource-body">
          {data.description}
        </ThumbnailBody>
      </StyledBox>
    </WrapperLink>
  );
};

export { Thumbnail, Tag, WrapperLink, tagstyle };
