import * as React from "react";
import { Thumbnail } from "../../components/thumbnail";
import styled from "styled-components";

const BlogThumbnail = styled(Thumbnail)`
  margin: 0 0 1.5em;
  .resource-title {
    /*TODO: find a way to embed the imported css */
    /* ${TitleCssStyle}; */
    color: white;
    font-family: "Lato", "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 800;
    color: white;
    height: 3em;
    box-sizing: border-box;
    text-shadow: 1.5px 1.5px #333333;
  }
  .resource-bg {
    background-color: grey;
  }
`;

export { BlogThumbnail };
